<template>
  <v-card>
    <v-card-title>Ghost Chat</v-card-title>
    <v-card-text>
      <messages />
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'Chat',

  components: {
    Messages: () => import('../components/Messages.vue')
  }
}
</script>
